import endpoint from "@/helper/endpoint.js";

export default {
  loginAPI(store, values) {
    return endpoint(store, "POST", "/v1/anonymizes/login", {}, {...values}).then(
      (response) => {
        if (response.token) {
          localStorage.setItem("jwtToken", response.token);
          store.commit("addJwtToken", response.token);
        }
        else {
          store.commit("loginError", response);
          throw response
        }
      }
    );
  },
}