import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.cyan.darken2,
        secondary: colors.orange.darken2,
        accent: colors.purple.darken2,
        chart: colors.lightGreen.darken2,
        default: colors.blueGrey.lighten2,
        background: colors.grey.lighten4,
        menuBackground: "#ffffff",
        card: colors.blueGrey.lighten5,
        toolbar: colors.blueGrey.lighten3,
        info: colors.indigo.accent2,
        warning: colors.orange.darken2,
        font: "#000",
        scrollbar: colors.grey.darken2,
      },
    }
  },
  breakpoint: {
    thresholds: {
      lg: 1920,
    },
  },
});
