import Vue from "vue";

export default {
  addJwtToken(state, payload) {
    Vue.set(state, "jwtToken", payload);
  },
  removeJwtToken(state) {
    Vue.set(state, "jwtToken", null);
  },
  loginError(state, error) {
    Vue.set(state, "loginError", error);
  }
};
