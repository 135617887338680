function endpoint(
  store,
  method,
  route,
  params,
  data,
  json = true,
  responseType = null
) {
  let jwtToken = localStorage.getItem("jwtToken");
  let headers = {
    Authorization:
    jwtToken && jwtToken !== "undefined" ? "Bearer " + jwtToken : "",
  };
  let body = null;
  if (data) {
    headers["Content-Type"] = "application/json";
    body = { ...data };
    delete body._id;
    body = JSON.stringify(body);
  }

  let url = route.includes("http")
    ? route
    : process.env.VUE_APP_APIURL + route.toLowerCase();
  if (params) url += formatParams(params);

  let requestObject = {
    method,
    body,
    headers,
  };

  if (responseType !== null) requestObject[responseType] = responseType;

  return fetch(url, requestObject)
    .then(async (response) => {
      switch (response.status) {
        case 200: {
          let result = json ? await response.json() : await response;
          if (result.code && result.code === 401) {
            localStorage.removeItem("jwtToken")
            store.commit("removeJwtToken")
          }
          return result
        }
        case 201:
          return {
            response: await response.json()
          }
        case 204:
          return {};
        default:
          return response.json().then((error) => {
            throw error;
          });
      }
    })
    .catch((error) => {
      throw error;
    });
}

function formatParams(params) {
  let query = "";
  Object.keys(params).map((key, i) => {
    if (typeof params[key] !== "undefined" && "" + params[key] !== "") {
      let delimiter = i === 0 ? "?" : "&";
      query += delimiter + key + "=" + params[key];
    }
  });
  return query;
}

export default endpoint;
