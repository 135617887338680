import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: {
      authorize: false,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/",
    name: "Case",
    meta: {
      authorize: true
    },
    component: () =>
      import(/* webpackChunkName: "case" */ "@/views/Case.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.authorize && !localStorage.getItem("jwtToken")) {
    return next("/login")
  }
  next()
});

export default router;
