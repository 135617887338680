import endpoint from "@/helper/endpoint.js";

export default {
  anonData(store, values) {
    return endpoint(store, "POST", "/v1/anonymizes/anonmcanonfaces", {}, {...values}).then(
      (response) => {
        store.commit("addETA", {
          response
        });
      }
    );
  },
  getStatus(store, referenceNumber) {
    return endpoint(store, "GET", "/v1/anonymizes/status" , {referenceNumber}).then(
      (response) => {
        store.commit("addStatus", {
          response
        });
      }
    );
  },
  getErrorStatus(store) {
    return endpoint(store, "GET", "/v1/anonymizes/errorstatus", {}).then(
      (response) => {
        store.commit("addErrorStatus", {
          response
        });
      }
    );
  }
}