import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: {
      GET: [],
      POST: [],
      PATCH: [],
      DELETE: [],
    }
  },
  modules
});
