import Vue from "vue";

export default {
  addStatus(state, payload) {
    Vue.set(state, "status", payload.response);
  },
  addErrorStatus(state, payload) {
    Vue.set(state, "errorStatus", payload.response);
  },
  addETA(state, payload) {
    Vue.set(state, "eta", payload.response);
  }
};
