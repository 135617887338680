import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state: {
    values: {},
    errors: {},
    changedValues: {},
    ignoreValidation: {},
  },
  mutations,
  actions,
};
