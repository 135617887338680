import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSanitize from "vue-sanitize";
import "@mdi/font/css/materialdesignicons.css";
import { VueReCaptcha } from "vue-recaptcha-v3";


Vue.use(VueSanitize);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
