import Vue from "vue";

export default {
  change(state, payload) {
    if (!state.values[payload.form]) Vue.set(state.values, payload.form, {});
    let keys = payload.key.split(".");
    let key = keys.pop();
    let values = state.values[payload.form];
    keys.forEach((item) => {
      values = values[item];
    });
    Vue.set(values, key, payload.value);

    if (!state.changedValues[payload.form])
      state.changedValues[payload.form] = [];
    if (
      !state.changedValues[payload.form].includes(payload.key) &&
      !payload.init
    ) {
      state.changedValues[payload.form] = [
        ...state.changedValues[payload.form],
        payload.key,
      ];
    }
  },
  setErrors(state, payload) {
    Vue.set(state.errors, payload.form, payload.errors);
  },
  resetError(state, payload) {
    Vue.set(state.errors[payload.form], payload.key, null);
  },
  clear(state, form) {
    if (form) {
      Vue.set(state.values, form, {});
      Vue.set(state.errors, form, {});
      Vue.set(state.changedValues, form, []);
    } else {
      Object.assign(state, {
        values: {},
        errors: {},
        changedValues: {},
      });
    }
  },
};
